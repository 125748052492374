import React from "react"
import { Link } from "gatsby"
export default function Back() {
  return (
    <div className="fixed top-4 left-4 z-10">
    <Link to="/">
    <div className="flex items-center justify-center h-12 w-12 rounded-md border border-yellow-500 hover:bg-yellow-500 hover:border-white hover:text-white text-yellow-500 z-10">
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
    </svg>
    </div>
    </Link>
  </div>
  )
}