import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Back from '../components/back'

class StrollTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalClass: "hidden",
      src: ""
    };
  }
  showModalImage(src){
    console.log("SHOW MODAL",src)
    this.setState(state => ({
      src: src,
      modalClass: ""
    }));
  }
  hideModalImage(){
    this.setState(state => ({
      src: state.src,
      modalClass: "hidden"
    }));
  }
  render() {
    const stroll = get(this.props, 'data.contentfulStroll')
    //const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const mapUrl = "https://www.google.com/maps/search/?api=1&query="+stroll.location.lat+","+stroll.location.lon+"&zoom=8"
    stroll.photos.sort((a,b)=>{
      console.log(a.createdAt)
      return(a.createdAt>b.createdAt)
    })
    let columns = [[],[],[]]
    let sizes = [0,0,0]
    columns[0].push(stroll.selfie)
    sizes[0] += 1/stroll.selfie.fluid.aspectRatio
    stroll.photos.map((photo)=>{
      console.log(sizes)
      const min = Math.min(...sizes)
      console.log("min",min)
      const ind = sizes.indexOf(min)
      console.log("ind",ind)
      columns[ind].push(photo)
      sizes[ind] += 1/photo.fluid.aspectRatio
    })
    return (
      <Layout>

        <div className="relative bg-gray-900 overflow-hidden">
          <div className="max-w-7xl mx-auto mt-10 md:mt-0">
            <div className="relative z-10 pb-8 bg-gray-900 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
              <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-gray-900 transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                <polygon points="50,0 100,0 50,100 0,100" />
              </svg>

              <div className="relative pt-6 px-4 sm:px-6 lg:px-8">

              </div>

              <main className="mt-10 mx-auto w-full px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">

                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-500 sm:text-5xl md:text-6xl">
                    <span className="block text-white xl:inline">{stroll.title}</span>
                  </h1>
                  <a className="flex space-x-3 mt-4" href={mapUrl} target="_map">
                    
                    <svg className="flex-shrink-0 h-5 w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                    <span className="text-base font-semibold text-gray-300">{stroll.city}</span>
                  </a>
                  <h2 className="mt-4 text-2xl tracking-tight font-extrabold text-yellow-500 sm:text-3xl md:text-4xl">
                    {stroll.distance} km
                  </h2>

                  <p className="mt-3 text-base text-gray-100 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    {stroll.datetime}
                  </p>
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <a href={stroll.hikeUrl} target="_ext">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <dt className="text-lg leading-6 font-medium text-white">
                            Hike Details (French)
                        </dt>
                          <dd className="mt-2 text-base text-gray-300 truncate">
                            {stroll.hikeUrl}
                          </dd>
                        </div>

                      </div>
                    </a>
                  </div>
                </div>
              </main>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 pointer-events-auto cursor-pointer" >
            <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={stroll.featured.fluid.src} alt="" onClick={()=>this.showModalImage(stroll.featured.fluid.src)} />
          </div>
        </div>


        <div className="flex p-0 md:p-8 bg-white">
          <div className="flex-col">
            {columns[0].map(({ fluid }, index) => {
                return (
                  <div key={fluid.src} className="w-full p-1 md:p-2">
                    <img className="rounded-lg pointer-events-auto cursor-pointer" src={fluid.src} alt="" onClick={()=>this.showModalImage(fluid.src)} />
                  </div>
                )
            })}
          </div>
          <div className="flex-col">
            {columns[1].map(({ fluid }, index) => {
                return (
                  <div key={fluid.src} className="w-full p-1 md:p-2">
                    <img className="rounded-lg pointer-events-auto cursor-pointer" src={fluid.src} alt="" onClick={()=>this.showModalImage(fluid.src)} />
                  </div>
                )
            })}
          </div>
          <div className="flex-col">
            {columns[2].map(({ fluid }, index) => {
                return (
                  <div key={fluid.src} className="w-full p-1 md:p-2">
                    <img className="rounded-lg pointer-events-auto cursor-pointer" src={fluid.src} alt="" onClick={()=>this.showModalImage(fluid.src)} />
                  </div>
                )
            })}
          </div>
        </div>
        <div aria-hidden="true" className={this.state.modalClass + " fixed top-0 left-0 flex h-screen z-50 bg-gray-900 w-full"} onClick={this.hideModalImage.bind(this)} onKeyUp={this.hideModalImage.bind(this)}>
          <div className="m-auto">
          <img className="max-w-full rounded-md" src={this.state.src} alt=""/>
          </div>
        </div>
        <Back></Back>
      </Layout>
    )
  }
}

export default StrollTemplate

export const pageQuery = graphql`
query StrollBySlug($slug: String!) {
  contentfulStroll(slug: {eq: $slug}) {
    title
    datetime(formatString: "MMMM Do, YYYY")
    photos {
      fluid {
        src
        aspectRatio
      }
      createdAt
    }
    featured {
      fluid {
        src
      }
    }
    selfie {
      fluid {
        src
        aspectRatio
      }
    }
    distance
    hikeUrl
    location {
      lat
      lon
    }
    city
  }
}

`
